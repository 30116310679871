@tailwind base;
@tailwind components;
@tailwind utilities;

/* General Styles */
html, body, #root {
  height: 100%;
  margin: 0;
  font-family: 'Fira Sans', sans-serif;
}

.fixed-size {
  width: 700px;
  height: 400px;
  object-fit: cover;
}

.size-for-contact {
  width: 1200px;
}

/* styles.css */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

